

.background {
    background-color: #212121
}

.background2 {
    background-color: #323232;
}


/* Card Width */
.cardWidth {
    width: 18rem;
}

.cardWidth02 {
    width: 35rem;
    border: 0px;
}

/*Text Color*/
.text {
    color: #14b5ff
}

/*Properties for all of the buttons with an animation effect*/
.button {
    background: linear-gradient(#2871fa, #6717cd);
    border-color: linear-gradient(#6717cd,#2871fa);
    color: white;
    transition: .3s;
}
.button:hover {
    animation: pulse 1s infinite;
    transition: .3s;
}
@keyframes pulse {
    0% {
        transform: scale(1);
    } 
    70% {
        transform: scale(.9);
    } 
    100% {
        transform: scale(1);
    }
}

/*This contains all elements within each other with the use of .withIn, .homeImage, and .itemCenter*/
.withIn {
    height: 100%;
    position: relative;
    overflow: hidden;
}
.homeImage {
    object-fit: cover;
    display: block;
    margin: auto;
    width: 100%;
    height: 100%;
}
.homeIcon {
    object-fit: cover;
    display: block;
    margin: auto;
    width: 50rem;
}

.itemCenter {
    color: white;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

/*Navbar background color*/
.navBackground {
    background: linear-gradient(#2871fa, #6717cd) !important;
    transition: background-color 200ms linear;
}

.serviceImg {
    width: 20em;
}